import requests from '../requests'

export default {
  list(showIgnored) {
    return requests.get(`enquiries?show_ignored=${showIgnored}`)
  },
  get(id) {
    return requests.get(`enquiries/${id}`)
  },
  create(obj) {
    return requests.post(`enquiries`, obj)
  },
  update(obj) {
    return requests.put(`enquiries/${obj.id}`, obj)
  },
  delete(obj) {
    return requests.delete(`enquiries/${obj.id}`)
  },
  activate(obj, data) {
    return requests.put(`enquiries/${obj.id}/activate`, data)
  },
  ignore(obj) {
    return requests.put(`enquiries/${obj.id}/ignore`)
  },
  restore(obj) {
    return requests.delete(`enquiries/${obj.id}/ignore`)
  },
  reassign(obj, data) {
    return requests.put(`enquiries/${obj.id}/assignment`, data)
  }
}
